import Web3 from 'web3';

let url = window.ethereum;
const web3 = new Web3(url);
let isInitialized = false;
let selectedAccount;


export const maxSupply = async (props) => {
    const cb_abi = 
    [
      {
         "inputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"constructor"
      },
      {
         "anonymous":false,
         "inputs":[
            {
               "indexed":true,
               "internalType":"address",
               "name":"owner",
               "type":"address"
            },
            {
               "indexed":true,
               "internalType":"address",
               "name":"approved",
               "type":"address"
            },
            {
               "indexed":true,
               "internalType":"uint256",
               "name":"tokenId",
               "type":"uint256"
            }
         ],
         "name":"Approval",
         "type":"event"
      },
      {
         "anonymous":false,
         "inputs":[
            {
               "indexed":true,
               "internalType":"address",
               "name":"owner",
               "type":"address"
            },
            {
               "indexed":true,
               "internalType":"address",
               "name":"operator",
               "type":"address"
            },
            {
               "indexed":false,
               "internalType":"bool",
               "name":"approved",
               "type":"bool"
            }
         ],
         "name":"ApprovalForAll",
         "type":"event"
      },
      {
         "anonymous":false,
         "inputs":[
            {
               "indexed":true,
               "internalType":"address",
               "name":"previousOwner",
               "type":"address"
            },
            {
               "indexed":true,
               "internalType":"address",
               "name":"newOwner",
               "type":"address"
            }
         ],
         "name":"OwnershipTransferred",
         "type":"event"
      },
      {
         "anonymous":false,
         "inputs":[
            {
               "indexed":true,
               "internalType":"address",
               "name":"from",
               "type":"address"
            },
            {
               "indexed":true,
               "internalType":"address",
               "name":"to",
               "type":"address"
            },
            {
               "indexed":true,
               "internalType":"uint256",
               "name":"tokenId",
               "type":"uint256"
            }
         ],
         "name":"Transfer",
         "type":"event"
      },
      {
         "inputs":[
            {
               "internalType":"address",
               "name":"to",
               "type":"address"
            },
            {
               "internalType":"uint256",
               "name":"tokenId",
               "type":"uint256"
            }
         ],
         "name":"approve",
         "outputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"address",
               "name":"owner",
               "type":"address"
            }
         ],
         "name":"balanceOf",
         "outputs":[
            {
               "internalType":"uint256",
               "name":"",
               "type":"uint256"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            
         ],
         "name":"cost",
         "outputs":[
            {
               "internalType":"uint256",
               "name":"",
               "type":"uint256"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"uint256",
               "name":"tokenId",
               "type":"uint256"
            }
         ],
         "name":"getApproved",
         "outputs":[
            {
               "internalType":"address",
               "name":"",
               "type":"address"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            
         ],
         "name":"hiddenMetadataUri",
         "outputs":[
            {
               "internalType":"string",
               "name":"",
               "type":"string"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"address",
               "name":"owner",
               "type":"address"
            },
            {
               "internalType":"address",
               "name":"operator",
               "type":"address"
            }
         ],
         "name":"isApprovedForAll",
         "outputs":[
            {
               "internalType":"bool",
               "name":"",
               "type":"bool"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            
         ],
         "name":"maxMintAmountPerTx",
         "outputs":[
            {
               "internalType":"uint256",
               "name":"",
               "type":"uint256"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            
         ],
         "name":"maxSupply",
         "outputs":[
            {
               "internalType":"uint256",
               "name":"",
               "type":"uint256"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"uint256",
               "name":"_mintAmount",
               "type":"uint256"
            }
         ],
         "name":"mint",
         "outputs":[
            
         ],
         "stateMutability":"payable",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"uint256",
               "name":"_mintAmount",
               "type":"uint256"
            },
            {
               "internalType":"address",
               "name":"_receiver",
               "type":"address"
            }
         ],
         "name":"mintForAddress",
         "outputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"function"
      },
      {
         "inputs":[
            
         ],
         "name":"name",
         "outputs":[
            {
               "internalType":"string",
               "name":"",
               "type":"string"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            
         ],
         "name":"owner",
         "outputs":[
            {
               "internalType":"address",
               "name":"",
               "type":"address"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"uint256",
               "name":"tokenId",
               "type":"uint256"
            }
         ],
         "name":"ownerOf",
         "outputs":[
            {
               "internalType":"address",
               "name":"",
               "type":"address"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            
         ],
         "name":"paused",
         "outputs":[
            {
               "internalType":"bool",
               "name":"",
               "type":"bool"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            
         ],
         "name":"renounceOwnership",
         "outputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"function"
      },
      {
         "inputs":[
            
         ],
         "name":"revealed",
         "outputs":[
            {
               "internalType":"bool",
               "name":"",
               "type":"bool"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"address",
               "name":"from",
               "type":"address"
            },
            {
               "internalType":"address",
               "name":"to",
               "type":"address"
            },
            {
               "internalType":"uint256",
               "name":"tokenId",
               "type":"uint256"
            }
         ],
         "name":"safeTransferFrom",
         "outputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"address",
               "name":"from",
               "type":"address"
            },
            {
               "internalType":"address",
               "name":"to",
               "type":"address"
            },
            {
               "internalType":"uint256",
               "name":"tokenId",
               "type":"uint256"
            },
            {
               "internalType":"bytes",
               "name":"_data",
               "type":"bytes"
            }
         ],
         "name":"safeTransferFrom",
         "outputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"address",
               "name":"operator",
               "type":"address"
            },
            {
               "internalType":"bool",
               "name":"approved",
               "type":"bool"
            }
         ],
         "name":"setApprovalForAll",
         "outputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"uint256",
               "name":"_cost",
               "type":"uint256"
            }
         ],
         "name":"setCost",
         "outputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"string",
               "name":"_hiddenMetadataUri",
               "type":"string"
            }
         ],
         "name":"setHiddenMetadataUri",
         "outputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"uint256",
               "name":"_maxMintAmountPerTx",
               "type":"uint256"
            }
         ],
         "name":"setMaxMintAmountPerTx",
         "outputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"bool",
               "name":"_state",
               "type":"bool"
            }
         ],
         "name":"setPaused",
         "outputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"bool",
               "name":"_state",
               "type":"bool"
            }
         ],
         "name":"setRevealed",
         "outputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"string",
               "name":"_uriPrefix",
               "type":"string"
            }
         ],
         "name":"setUriPrefix",
         "outputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"string",
               "name":"_uriSuffix",
               "type":"string"
            }
         ],
         "name":"setUriSuffix",
         "outputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"bytes4",
               "name":"interfaceId",
               "type":"bytes4"
            }
         ],
         "name":"supportsInterface",
         "outputs":[
            {
               "internalType":"bool",
               "name":"",
               "type":"bool"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            
         ],
         "name":"symbol",
         "outputs":[
            {
               "internalType":"string",
               "name":"",
               "type":"string"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"uint256",
               "name":"_tokenId",
               "type":"uint256"
            }
         ],
         "name":"tokenURI",
         "outputs":[
            {
               "internalType":"string",
               "name":"",
               "type":"string"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            
         ],
         "name":"totalSupply",
         "outputs":[
            {
               "internalType":"uint256",
               "name":"",
               "type":"uint256"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"address",
               "name":"from",
               "type":"address"
            },
            {
               "internalType":"address",
               "name":"to",
               "type":"address"
            },
            {
               "internalType":"uint256",
               "name":"tokenId",
               "type":"uint256"
            }
         ],
         "name":"transferFrom",
         "outputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"address",
               "name":"newOwner",
               "type":"address"
            }
         ],
         "name":"transferOwnership",
         "outputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"function"
      },
      {
         "inputs":[
            
         ],
         "name":"uriPrefix",
         "outputs":[
            {
               "internalType":"string",
               "name":"",
               "type":"string"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            
         ],
         "name":"uriSuffix",
         "outputs":[
            {
               "internalType":"string",
               "name":"",
               "type":"string"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            {
               "internalType":"address",
               "name":"_owner",
               "type":"address"
            }
         ],
         "name":"walletOfOwner",
         "outputs":[
            {
               "internalType":"uint256[]",
               "name":"",
               "type":"uint256[]"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      },
      {
         "inputs":[
            
         ],
         "name":"withdraw",
         "outputs":[
            
         ],
         "stateMutability":"nonpayable",
         "type":"function"
      }
   ];
    const cb_add = '0xEB4F5d458B135A8993239f91ab3f45Ab92CD519e'
    
    const cb_contract = new web3.eth.Contract(cb_abi, cb_add);
    return cb_contract.methods.maxSupply().call();
};